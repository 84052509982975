<link rel="stylesheet" href="../../../dispatch_aht/src/scss/style.scss">
<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <div class="mb-2 flex gap-3">
                <div class="mr-2 border border-gray-200 bg-white rounded p-3 py-2 text-center w-full">
                    <h6 class="text-lg font-bold">Orders In Queue</h6>
                    <h3 class="text-xl font-semibold">{{inQueue}}</h3>
                </div>

                <div class="ml-2 border border-gray-200 bg-white rounded p-3 py-2 text-center w-full">
                    <h6 class="text-lg font-bold">Fabrication Outstanding</h6>
                    <h3 class="text-xl font-semibold">{{outstandingFab}}</h3>
                </div>
            </div>

            <div class="mb-2 flex">
                <div class="btn-group">
                    <button class="button" @click="mode='today'" :class="{'active': mode === 'today'}">Today</button>
                    <button class="button" @click="mode='yesterday'" :class="{'active': mode === 'yesterday'}">Yesterday</button>
                    <button class="button" @click="mode='wtd'" :class="{'active': mode === 'wtd'}">WTD</button>
                    <button class="button" @click="mode='mtd'" :class="{'active': mode === 'mtd'}">MTD</button>
                </div>

                <div class="ml-3 flex">
                    <div class="checkbox">
                        <input type="checkbox" id="checkbox" v-model="showManagement"/>
                        <label for="checkbox">Show Management</label>
                    </div>
                </div>

                <p class="ml-auto"><span class="opacity-50">Last Updated:</span> <span class="text-green-500">{{format(lastUpdated)}}</span></p>
            </div>
            <div class="inline-block min-w-full border border-gray-200 rounded-lg overflow-hidden">
                <table class="min-w-full leading-normal">
                    <thead>
                    <tr>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Username</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" v-if="mode==='today' || mode==='yesterday'">Productivity</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">AHT</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" v-if="mode==='today'">AHT Diff</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Completed</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Skipped</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">OAP</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Booked In</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="data in allTimes" :key="data.username">
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.username}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm" v-if="mode==='today' || mode==='yesterday'">{{data.productivity || 0}}%</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{fixed(data.aht)}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm" v-if="mode==='today'" :class="{'is_under': data.aht_diff < 0, 'is_over': data.aht_diff > 0}">{{data.aht_diff}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.completed}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.skipped}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.oap}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.booked_in}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th class="text-left px-5 py-3 bg-white text-sm">Totals</th>
                        <th class="text-left px-5 py-3 bg-white text-sm" v-if="mode==='today' || mode==='yesterday'"></th>
                        <th class="text-left px-5 py-3 bg-white text-sm">{{fixed(totalAHT)}}</th>
                        <th class="text-left px-5 py-3 bg-white text-sm" v-if="mode==='today'"></th>
                        <th class="text-left px-5 py-3 bg-white text-sm">{{totalCompleted}}</th>
                        <th class="text-left px-5 py-3 bg-white text-sm">{{totalSkipped}}</th>
                        <th class="text-left px-5 py-3 bg-white text-sm">{{totalOap}}</th>
                        <th class="text-left px-5 py-3 bg-white text-sm">{{totalBookedIn}}</th>
                    </tr>
                    </tfoot>
                </table>
            </div>

            <h3 class="mt-3 mb-2 font-bold text-md">Fabrication Complete</h3>
            <div class="inline-block min-w-full border border-gray-200 rounded-lg overflow-hidden">
                <table class="min-w-full leading-normal">
                    <thead>
                    <tr>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">SKU</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Title</th>
                        <th class="px-5 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Quantity</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="data in fabDataSource" :key="data.ID">
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.item}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.item_title}}</td>
                        <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">{{data.quantity}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import moment from 'moment';
export default defineComponent({
    name: "Aht",
    data() {
        return {
            dataSource: [],
            fabDataSource: [],
            mode: "today",
            lastUpdated: null,
            interval: null,
            inQueue: 0,
            outstandingFab: 0,
            unpickedFab: 0,
            allClockedOut: false,
            showManagement: false,
            totalAHT: 0,
            apiUrl: "https://api.darkside-developments.com",
            intervl: null
        }
    },
    mounted() {
        if(location.hostname === "localhost") {
            this.apiUrl = "https://darkside_api.test"
        }
        this.getToday()
        this.getInQueue()
        this.getOutstandingFab()
        this.fabReadyToPick()
        this.autoRefresh()
        this.intervl = setInterval(() => {
            this.checkAllClockedOut()
        }, 60000);
    },
    computed: {
        allTimes() {
            if(this.showManagement) {
                return this.dataSource;
            } else {
                const data = [];
                for (let index = 0; index < this.dataSource.length; index++) {
                    const element = this.dataSource[index];
                    if(element.management === false) {
                        data.push(element);
                    }
                }
                return data;
            }
        },
        totalCompleted() {
            let count = 0;
            this.allTimes.forEach(element => {
                count += element.completed
            });
            return count;
        },
        totalSkipped() {
            let count = 0;
            this.allTimes.forEach(element => {
                count += element.skipped
            });
            return count;
        },
        totalOap() {
            let count = 0;
            this.allTimes.forEach(element => {
                count += element.oap
            });
            return count;
        },
        totalBookedIn() {
            let count = 0;
            this.allTimes.forEach(element => {
                count += element.booked_in
            });
            return count;
        },
    },
    methods: {
        getToday() {
            this.$Progress.start()
            this.$http.get(this.apiUrl + '/api/dispatch/logs/aht/today').then(resp => {
                this.dataSource = resp.data.logs
                this.totalAHT = resp.data.aht
                this.$Progress.finish()
                this.lastUpdated = moment();
            }).catch((err) => {
                console.log(err)
                this.$Progress.finish()
            })
        },
        getYesterday() {
            this.$Progress.start()
            this.$http.get(this.apiUrl + '/api/dispatch/logs/aht/yesterday').then(resp => {
                this.dataSource = resp.data.logs
                this.totalAHT = resp.data.aht
                this.$Progress.finish()
                this.lastUpdated = moment();
            }).catch((err) => {
                console.log(err)
                this.$Progress.finish()
            })
        },
        getWtd() {
            this.$Progress.start()
            this.$http.get(this.apiUrl + '/api/dispatch/logs/aht/wtd').then(resp => {
                this.dataSource = resp.data.logs
                this.totalAHT = resp.data.aht
                this.$Progress.finish()
                this.lastUpdated = moment();
            }).catch((err) => {
                console.log(err)
                this.$Progress.finish()
            })
        },
        getMtd() {
            this.$Progress.start()
            this.$http.get(this.apiUrl + '/api/dispatch/logs/aht/mtd').then(resp => {
                this.dataSource = resp.data.logs
                this.totalAHT = resp.data.aht
                this.$Progress.finish()
                this.lastUpdated = moment();
            }).catch((err) => {
                console.log(err)
                this.$Progress.finish()
            })
        },
        getInQueue() {
            this.$http.get('https://plp.darksidedev.net/in-queue').then((resp) => {
                this.inQueue = resp.data.count;
            }).catch((err) => {
                console.log(err)
            })

            this.timeout1 = setTimeout(() => {
                this.getInQueue();
            }, 30000);
        },
        getOutstandingFab() {
            this.$http.get(this.apiUrl + '/api/dispatch/fab/outstanding').then((resp) => {
                this.outstandingFab = resp.data.count;
            }).catch((err) => {
                console.log(err)
            })

            this.timeout2 = setTimeout(() => {
                this.getOutstandingFab();
            }, 30000);
        },
        getUnpickedFab() {
            this.$http.get('https://api.darkside-developments.com/api/dispatch/fab/unpicked').then((resp) => {
                this.unpickedFab = resp.data.count;
            }).catch((err) => {
                console.log(err)
            })

            this.timeout3 = setTimeout(() => {
                this.getUnpickedFab();
            }, 30000);
        },
        autoRefresh() {
            if(this.interval) {
                clearInterval(this.interval);
            }
            this.interval = setInterval(() => {
                if(this.mode === 'today') {
                    this.getToday();
                } else if (this.mode === 'wtd') {
                    this.getWtd();
                } else if (this.mode === 'yesterday') {
                    this.getYesterday();
                } else {
                    this.getMtd();
                }
            }, 20000);
        },
        checkAllClockedOut() {
            this.$http.get('https://api.darkside-developments.com/api/dispatch/clockedout').then((resp) => {
                this.allClockedOut = !!resp.data.success;
            }).catch(() => {})
        },
        fabReadyToPick() {
            this.$http.get("https://api.darkside-developments.com/api/dispatch/orders/fab").then((resp) => {
                this.fabDataSource = resp.data;
            }).catch((err) => {
                console.log(err)
            })

            this.timeout4 = setTimeout(() => {
                this.fabReadyToPick();
            }, 30000);
        },
        fixed(val) {
            return val.toFixed(2);
        },
        format(val) {
            if(val) {
                return val.format('HH:mm:ss');
            }
        }
    },
    watch: {
        mode(val) {
            clearInterval(this.interval);
            if(val === 'today') {
                this.getToday();
                this.autoRefresh();
            } else if (val === 'wtd') {
                this.getWtd();
                this.autoRefresh();
            } else if (this.mode === 'yesterday') {
                this.getYesterday();
                this.autoRefresh();
            } else {
                this.getMtd();
                this.autoRefresh();
            }
        }
    },
    beforeRouteLeave() {
        if(this.intervl) {
            clearInterval(this.intervl)
            this.intervl = null;
        }

        if(this.interval) {
            clearInterval(this.interval)
            this.interval = null;
        }

        if(this.timeout1) {clearTimeout(this.timeout1); this.timeout1 = null}
        if(this.timeout2) {clearTimeout(this.timeout2); this.timeout2 = null}
        if(this.timeout3) {clearTimeout(this.timeout3); this.timeout3 = null}
        if(this.timeout4) {clearTimeout(this.timeout4); this.timeout4 = null}
    }
});
</script>

<style lang="scss">
.checkbox {
    --border-color: #d2d6dc;
    // --border-color: black;
    --color: #3f83f8;
    // --color: black;

    display: flex;
    align-items: center;

    input[type="checkbox"] {
        appearance: none;
        color-adjust: exact;
        vertical-align: middle;
        background-origin: border-box;
        user-select: none;
        flex-shrink: 0;
        height: 1rem;
        width: 1rem;
        color: var(--color);
        background-color: #fff;
        border-color: var(--border-color);
        border-width: 1px;
        border-radius: 0.25rem;
        border-style: solid;

        &:checked {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
            border-color: transparent;
            background-color: currentColor;
            background-size: 100% 100%;
            background-position: 50%;
            background-repeat: no-repeat
        }

        @media not print {
            &::-ms-check {
                border-width: 1px;
                color: transparent;
                background: inherit;
                border-color: inherit;
                border-radius: inherit;
            }
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(164, 202, 254, .45);
            border-color: #a4cafe;
        }

        &:checked:focus {
            border-color: transparent;
        }
    }

    label {
        margin-left: 0.5rem;
        display: block;
        line-height: 1.25rem;
    }
}
td.is_under {
    color: green;
}
td.is_over {
    color: red;
}
</style>
